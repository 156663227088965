import { pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import Link from '../../../../components/Link/Link';
import withGetYearGroupOptions from '../../../../language/withGetYearGroupOptions';
import Badge from '../../../../components/Badge/Badge';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import SearchStatus from '../../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import UserListing from '../../../../components/UserListing/UserListing';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import colors from '../../../../globals/colors';
import { INVITATION_STATUS, ARCHIVE_STATUS } from '../../../../globals/appConstants';
import { orgRoles as OrgRoles } from '../../../../globals/orgRoles';
import { searchStudentSortOptions } from '../../../../globals/searchFilters';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { setManagedUser, changeManagedUserPasswordRequest } from '../../../../redux/actions/managedUser';
import { clearForm } from '../../../../redux/reducers/addStudentsToClassroom';
import { closeForm, showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer';
import { setStudentSelected, clearSelectedUser } from '../../../../redux/reducers/classroomPage';
import { setFilter, setPage, setSort, setTerm, triggerSearch } from '../../../../redux/reducers/data/search.reducer';
import { setUsers as setStudentsToRemove } from '../../../../redux/reducers/removeStudentsFromClassroom';
import { setAccountToUnlock } from '../../../../redux/reducers/unlockAccount.reducer';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import AssignLearningMaterial from '../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import UnlockAccount from '../../../panels/UnlockAccount/UnlockAccount';
import AddStudentsPanel from './panels/AddStudents/AddStudents';
import RemoveStudentsPanel from './panels/RemoveStudents/RemoveStudents';
import { createAuthorizeUpdateAssignments } from '../../../../redux/selectors/authorization/organization';
import {
  createAuthorizeUnlockUser,
  createAuthorizeToJoinAClass,
  createAuthorizeDownloadSignInCard
} from '../../../../redux/selectors/authorization/user';
import ManagedUserSignInCardPanel from '../../../../panels/ManagedUserSignInCard/ManagedUserSignInCardPanel';
import { setStudentsToDownload, resetSignInCard } from '../../../../redux/reducers/userSignInCard.reducer';
import userRoles from '../../../../globals/userRoles';
import { featureIsEnabled, isLocal } from '../../../../globals/envSettings';
import { setUsers as setStudentsToManage } from '../../../../redux/reducers/manageJoinAClassRequest.reducer';
import ManageJoinRequest from './panels/ManageJoinRequest/ManageJoinRequest';
import ClassCodeHeader from '../../../../components/ClassCode/ClassCodeHeader';
import { isOrbMode, isHubMode } from '../../../../utils/platform';
import ProductFinderPanel from '../../../../components/ProductFinder/ProductFinderPanel';

function unlockAccount({
  orgId,
  selectedStudentIds,
  people,
  setAccountToUnlockAction,
  setManagedUserAction,
  changeManagedUserPasswordRequestAction
}) {
  const userId = selectedStudentIds[0];
  setManagedUserAction({
    userId,
    user: {
      firstName: people[userId].firstname,
      lastName: people[userId].lastname,
      username: people[userId].email && people[userId].email !== null ? people[userId].email : people[userId].username
    },
    orgId
  });

  setAccountToUnlockAction(selectedStudentIds);
  if (featureIsEnabled('lock-account')) {
    changeManagedUserPasswordRequestAction(orgId, selectedStudentIds);
  }
}

function StudentsTab({
  userRole,
  orgId,
  organisation,
  tabName,
  panelName,
  people,
  searchTerm,
  sort,
  page,
  filters,
  resultIds,
  totalResults,
  loading,
  error,
  selectedStudentIds,
  currentUserCanEditClass,
  showConfirmModal,
  classStudentsDataRecency,
  setStudentSelectedAction,
  triggerSearchAction,
  setSearchTerm,
  setSortAction,
  setPageAction,
  setFilterAction,
  setStudentsToRemoveAction,
  showModalAction,
  closeModalAction,
  clearFormAction,
  setManagedUserAction,
  changeManagedUserPasswordRequestAction,
  setAccountToUnlockAction,
  localizedContent: {
    classPageStudentsTab: content,
    removeLearningMaterialModal: removeMaterialModalContent,
    managedUserSignInCardPanel: managedUserSignInCardContent,
    manageJoinRequest: manageJoinRequestContent,
    productFinder: productFinderContent
  },
  canRoleUnlockAccount,
  canManageAssignments = false,
  classroomId,
  orgRole,
  yearGroupOptions,
  setStudentsToDownloadAction,
  canDownloadSignInCard,
  onResetSignInCard,
  setStudentsToManageAction,
  canManageJoinRequest,
  clearSelectedUserAction,
  openEditPanelOnClick,
  studentIdList
}) {
  const history = useHistory();
  const canUnlockAccount = selectedStudentIds.length && selectedStudentIds.every(id => people[id].isLocked);
  const isPrimarySchool = orgRole === OrgRoles.PRIMARY_SCHOOL;
  const canManageUserRequest =
    canManageJoinRequest && selectedStudentIds.every(id => people[id].orgInviteStatus === INVITATION_STATUS.REQUESTED);

  const hasSelectedArchivableStudents =
    currentUserCanEditClass &&
    selectedStudentIds.every(
      id =>
        !(
          people[id].orgArchiveStatus === ARCHIVE_STATUS.ARCHIVED ||
          people[id].isDeleted ||
          people[id][`classArchiveStatus-${classroomId}`] === ARCHIVE_STATUS.ARCHIVED
        )
    );
  const _getProductFinderContextName = () =>
    studentIdList.length === 1
      ? `${people[studentIdList[0]].firstname || ''} ${people[studentIdList[0]].lastname || ''}`
      : `${studentIdList.length} ${productFinderContent.students_text}`;

  return (
    <div>
      <ListPageControls
        idPrefix="classStudentSearch"
        searchInputLabel={isPrimarySchool ? content.search_input_label_text : content.search_input_label_text_secondary}
        searchInputPlaceholder={
          isPrimarySchool
            ? content.search_input_label_placeholder_text
            : content.search_input_label_placeholder_text_secondary
        }
        searchInputValue={searchTerm}
        searchInputOnChange={term => setSearchTerm(term, yearGroupOptions)}
        searchInputOnSubmit={triggerSearchAction}
        newButtonText={content.add_students_button_text}
        newButtonTo={currentUserCanEditClass ? safePanelLink('addStudents') : null}
        filterOptions={[
          {
            text: content.filter_active_students_text,
            id: 'classStudentSearch-filterActive',
            name: 'classStudentSearch-filterActive',
            value: 'active',
            checked: filters.active,
            onChange: setFilterAction
          },
          {
            text: content.filter_archive_students_text,
            id: 'classStudentSearch-filterArchived',
            name: 'classStudentSearch-filterArchived',
            value: 'archived',
            checked: filters.archived,
            onChange: setFilterAction
          }
        ]}
        sortOnChange={setSortAction}
        sortOptions={searchStudentSortOptions('classStudentSearch', sort, orgRole === OrgRoles.SECONDARY_SCHOOL)}
        ariaControls="searchResults"
        loading={classStudentsDataRecency.syncing}
        loadingMessage={content.loading_message}
        loaded={classStudentsDataRecency.synced}
        loadedMessage={content.loaded_message}
        showSkeletonLoader={loading}
      />

      {!isOrbMode() &&
      !isHubMode() &&
      userRole === userRoles.ORG_ADMIN &&
      classroomId &&
      !isPrimarySchool &&
      featureIsEnabled('class-joining-code') ? (
        <ClassCodeHeader orgId={orgId} classroomId={classroomId} openEditPanelOnClick={openEditPanelOnClick} />
      ) : null}

      <SearchStatus
        searchSource="classStudents"
        noResultsFoundContent={
          <div className="grid">
            <div className="row">
              <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                <p className="gin-top1 gin-bot1">{content.search_students_no_results}</p>
                {currentUserCanEditClass ? (
                  <Link to={safePanelLink('addStudents')}>{content.add_students_link_text}</Link>
                ) : null}
              </div>
            </div>
          </div>
        }
      />

      {!(loading || error) && resultIds.length > 0 ? (
        <div className="grid horizantal-scroll-mobile">
          <div className="row">
            <div
              id="searchResults"
              className="col"
              role="region"
              aria-live="polite"
              aria-atomic="true"
              aria-label="Classroom students list"
            >
              <UserListing
                className="gin-top2"
                items={pick(people, resultIds)}
                showYearGroups
                orgCurriculumType={organisation.curriculumType}
                selectable={
                  canRoleUnlockAccount ? Object.keys(pickBy(pick(people, resultIds), user => !user.isDeleted)) : false
                }
                selectedItems={selectedStudentIds}
                processingItems={classStudentsDataRecency.syncing ? classStudentsDataRecency.ids : []}
                onItemLabelClick={id => {
                  if (!isLocal()) {
                    window.newrelic.interaction().actionText('Open student profile');
                  }
                  history.push(`/org/${orgId}/user/${id}`);
                }}
                onItemSelect={id => setStudentSelectedAction(id, !selectedStudentIds.includes(id))}
                shouldShowClassCode={featureIsEnabled('class-joining-code') && userRole === 'ORG_ADMIN'}
                onRemoveClick={
                  currentUserCanEditClass
                    ? id => {
                        history.push(safePanelLink('removeStudents'));
                        setStudentsToRemoveAction([id]);
                      }
                    : null
                }
                classroomId={classroomId}
                selectAllEnabled
              />

              {totalResults > 10 ? (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="classStudentSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <SlideupPanel isOpen={!!selectedStudentIds.length}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '2rem' }}>
            <Badge backgroundColor={colors.DASHBOARD} value={selectedStudentIds.length} />
            <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_student_text}</span>
          </div>
          {canRoleUnlockAccount && canUnlockAccount ? (
            <LinkWithIcon
              id="openUnlockAccounts"
              to={safePanelLink('unlockAccount')}
              action={
                () =>
                  unlockAccount({
                    orgId,
                    selectedStudentIds,
                    people,
                    setAccountToUnlockAction,
                    setManagedUserAction,
                    changeManagedUserPasswordRequestAction
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              text={content.unlock_account}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
          {canManageUserRequest && featureIsEnabled('join-a-class') ? (
            <LinkWithIcon
              id="openManageJoinRequest"
              to={safePanelLink('manageRequest')}
              action={() => setStudentsToManageAction(selectedStudentIds)}
              text={manageJoinRequestContent.manage_join_request_text}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
          {canDownloadSignInCard ? (
            <LinkWithIcon
              id="viewSignInCardLink"
              to={safePanelLink('viewSignInCard')}
              action={() => setStudentsToDownloadAction(selectedStudentIds)}
              text={content.download_sign_in_card}
              subtext={content.download_sign_in_card_subtext}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
          {hasSelectedArchivableStudents ? (
            <LinkWithIcon
              id="openRemoveStudents"
              to={safePanelLink('removeStudents')}
              action={() => setStudentsToRemoveAction(selectedStudentIds)}
              text={content.remove_students_text}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
          {currentUserCanEditClass && canManageAssignments ? (
            <LinkWithIcon
              id="openAssignToStudents"
              to={safePanelLink('assignMaterial')}
              text={content.assign_learning_material_text}
              glyph={GLYPHS.ICON_RIGHT}
            />
          ) : null}
        </div>
      </SlideupPanel>
      {canManageUserRequest && featureIsEnabled('join-a-class') ? (
        <ManageJoinRequest
          isOpen={panelName === 'manageRequest'}
          closePanel={() => showModalAction(true)}
          onComplete={() => {
            history.push(safePanelUnlink('manageRequest'));
          }}
        />
      ) : null}
      {canDownloadSignInCard ? (
        <ManagedUserSignInCardPanel
          id="ManagedUserSignInCardPanelPopout"
          ariaLabel={managedUserSignInCardContent.aria_label_view_sign_in_card}
          orgId={orgId}
          userType="students"
          open={tabName === 'students' && panelName === 'viewSignInCard'}
          onClosePanel={() => showModalAction(true)}
          onComplete={() => {
            history.push(safePanelUnlink('viewSignInCard'));
            clearSelectedUserAction();
          }}
        />
      ) : null}
      {canRoleUnlockAccount && canUnlockAccount ? (
        <UnlockAccount
          people={people}
          orgId={orgId}
          userIds={selectedStudentIds}
          panelId="unlockAccountsPopout"
          ariaLabel={content.aria_label_unlock_account}
          open={tabName === 'students' && panelName === 'unlockAccount'}
          onClose={() => history.push(safePanelUnlink('unlockAccount'))}
        />
      ) : null}
      {currentUserCanEditClass ? (
        <div>
          <AddStudentsPanel
            isOpen={panelName === 'addStudents'}
            orgId={orgId}
            closePanel={() => showModalAction(true)}
            onComplete={() => history.push(safePanelUnlink('addStudents'))}
            classId={classroomId}
          />

          <RemoveStudentsPanel
            isOpen={panelName === 'removeStudents'}
            closePanel={() => showModalAction(true)}
            onComplete={() => {
              history.push(safePanelUnlink('removeStudents'));
            }}
          />

          {canManageAssignments ? (
            <PopoutPanel
              id="assignToStudentsPopout"
              ariaLabel={content.aria_label_assign_learning_material}
              isOpen={tabName === 'students' && panelName === 'assignMaterial'}
            >
              {featureIsEnabled('product-finder-refactor') && isHubMode() ? (
                <ProductFinderPanel
                  orgId={orgId}
                  selectedUsers={{ studentIdList: [...studentIdList] }}
                  contextName={_getProductFinderContextName()}
                  onClose={() => showModalAction(true)}
                  onComplete={() => {
                    clearSelectedUserAction();
                    history.push(safePanelUnlink('assignMaterial'));
                  }}
                  // needed for polling. will be removed when polling is removed
                  context="CLASS_STUDENTS"
                />
              ) : (
                <AssignLearningMaterial
                  context="CLASS_STUDENTS"
                  orgId={orgId}
                  closePopoutAction={() => showModalAction(true)}
                  onComplete={() => {
                    clearSelectedUserAction();
                    history.push(safePanelUnlink('assignMaterial'));
                  }}
                />
              )}
            </PopoutPanel>
          ) : null}
        </div>
      ) : null}
      {showConfirmModal ? (
        <ConfirmationModal
          title={removeMaterialModalContent.title}
          body={removeMaterialModalContent.body}
          positiveClickText={removeMaterialModalContent.positiveClickText}
          negativeClickText={removeMaterialModalContent.negativeClickText}
          positiveClick={() => {
            // Close the Popout panel
            showModalAction(false);
            // Once its closed then reset the archiveUsers form
            setTimeout(closeModalAction, 300);
            setTimeout(clearFormAction, 300);
            history.push(safePanelUnlink('assignMaterial'));
            history.push(safePanelUnlink('removeStudents'));
            history.push(safePanelUnlink('addStudents'));
            history.push(safePanelUnlink('viewSignInCard'));
            clearSelectedUserAction();
            onResetSignInCard();
          }}
          negativeClick={() => showModalAction(false)}
        />
      ) : null}
    </div>
  );
}

StudentsTab.propTypes = {
  userRole: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  organisation: PropTypes.shape({
    curriculumType: PropTypes.string.isRequired
  }).isRequired,
  tabName: PropTypes.string,
  panelName: PropTypes.string,
  selectedStudentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  // Allow if role is greater than teacher Or if role is TEACHER and is a teacher of this class
  currentUserCanEditClass: PropTypes.bool.isRequired,
  people: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  classStudentsDataRecency: PropTypes.object.isRequired,
  setStudentsToRemoveAction: PropTypes.func.isRequired,
  setStudentSelectedAction: PropTypes.func.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  closeModalAction: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired,
  setAccountToUnlockAction: PropTypes.func.isRequired,
  setManagedUserAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  canManageAssignments: PropTypes.bool,
  canRoleUnlockAccount: PropTypes.bool,
  canDownloadSignInCard: PropTypes.bool,
  classroomId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  yearGroupOptions: PropTypes.object.isRequired,
  setStudentsToDownloadAction: PropTypes.func.isRequired,
  onResetSignInCard: PropTypes.func.isRequired,
  setStudentsToManageAction: PropTypes.func.isRequired,
  canManageJoinRequest: PropTypes.bool,
  clearSelectedUserAction: PropTypes.func,
  openEditPanelOnClick: PropTypes.func,
  studentIdList: PropTypes.array,
  changeManagedUserPasswordRequestAction: PropTypes.func.isRequired
};

export default compose(
  withGetYearGroupOptions,
  withLocalizedContent(
    'classPageStudentsTab',
    'removeLearningMaterialModal',
    'managedUserSignInCardPanel',
    'manageJoinRequest'
  ),
  withDataRecency('classStudents'),
  connect(
    (state, { orgId, getYearGroupOptions }) => {
      const isPrimarySchool = state.organisations.data[orgId].role === OrgRoles.PRIMARY_SCHOOL;
      const targetUserRole = isPrimarySchool ? userRoles.MANAGED_USER : userRoles.LEARNER;
      const classroomId = state.classroomPage.classroomId;
      return {
        userRole: state.identity.role,
        people: state.people.data,
        organisation: state.organisations.data[orgId],
        selectedStudentIds: state.classroomPage.selectedStudentIds,
        ...pick(state.search.classStudents, ['sort', 'page', 'filters', 'totalResults', 'loading', 'error']),
        searchTerm: state.search.classStudents.term,
        canManageJoinRequest: createAuthorizeToJoinAClass(state)({ orgId }),
        resultIds: state.search.classStudents.ids,
        showConfirmModal: state.archiveUsers.showModal,
        canManageAssignments: createAuthorizeUpdateAssignments(state)({ orgId }),
        canRoleUnlockAccount: createAuthorizeUnlockUser(state)({ orgId }),
        classroomId,
        orgRole: state.organisations.data[orgId].role,
        yearGroupOptions: getYearGroupOptions(state.organisations.data[orgId].curriculumType),
        canDownloadSignInCard: createAuthorizeDownloadSignInCard(state)({ orgId, targetUserRole }),
        studentIdList: state.classroomPage[classroomId]?.studentIdList || []
      };
    },
    {
      setStudentsToRemoveAction: setStudentsToRemove,
      setStudentSelectedAction: setStudentSelected,
      setAccountToUnlockAction: setAccountToUnlock,
      setStudentsToManageAction: setStudentsToManage,
      setSearchTerm: (term, yearGroupOptions) => setTerm('classStudents', term, yearGroupOptions),
      setSortAction: sort => setSort('classStudents', sort[0]),
      setPageAction: page => setPage('classStudents', page),
      setFilterAction: (valueName, value) => setFilter('classStudents', valueName, value),
      triggerSearchAction: triggerSearch.bind(null, 'classStudents'),
      showModalAction: showClosePanelModal,
      closeModalAction: closeForm,
      clearFormAction: clearForm,
      setManagedUserAction: payload => setManagedUser(payload),
      setStudentsToDownloadAction: setStudentsToDownload,
      onResetSignInCard: resetSignInCard,
      clearSelectedUserAction: clearSelectedUser,
      changeManagedUserPasswordRequestAction: (orgId, userId) => changeManagedUserPasswordRequest(orgId, userId)
    }
  )
)(StudentsTab);
