import { pick, pickBy, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import Badge from '../../../../components/Badge/Badge';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Link from '../../../../components/Link/Link';
import LinkWithIcon from '../../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../../components/ListPageControls/ListPageControls';
import PaginationButtons from '../../../../components/PaginationButtons/PaginationButtons';
import PopoutPanel from '../../../../components/PopoutPanel/PopoutPanel';
import withSearchInitialiser from '../../../../components/SearchInitialiser/withSearchInitialiser';
import SearchStatus from '../../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import UserListing, { ListingTypes } from '../../../../components/UserListing/UserListing';
import withDataRecency from '../../../../dataRecency/withDataRecency';
import colors from '../../../../globals/colors';
import { searchUserSortOptions } from '../../../../globals/searchFilters';
import userRoles, { roleCanArchiveOrgUser, roleCanRegisterUser } from '../../../../globals/userRoles';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import ResendInvitationOutcomePanel from '../../../../panels/ResendInvitationOutcomePanel';
import UserArchivePanel from '../../../../panels/UserArchivePanel';
import { setManagedUser, changeManagedUserPasswordRequest } from '../../../../redux/actions/managedUser';
import { resendInvitationRequest, resendInvitationReset } from '../../../../redux/actions/resendInvitation';
import { closeForm, setStaffToArchive, showClosePanelModal } from '../../../../redux/reducers/archiveUsers.reducer';
import { resetJourney } from '../../../../redux/reducers/assignLearningMaterial.reducer';
import {
  setFilter,
  setPage,
  setSort,
  setTerm,
  triggerSearch,
  setFilterOrgStudent,
  setPageOrgStudent,
  setSortOrgStudent
} from '../../../../redux/reducers/data/search.reducer';
import { clearSelectedUser, setTeacherSelected } from '../../../../redux/reducers/organisationPage.reducer';
import { clearStoreClassrooms } from '../../../../redux/reducers/data/classrooms';
import { setAccountToUnlock } from '../../../../redux/reducers/unlockAccount.reducer';
import { isAdminOrSupport } from '../../../../redux/selectors/authorization/user';
import AssignLearningMaterial from '../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import { USER_ARCHIVED_STATUS } from '../../../../utils/archiveStatuses';
import { safePanelLink, safePanelUnlink } from '../../../../utils/links/panelLinks';
import UnlockAccount from '../../../panels/UnlockAccount/UnlockAccount';
import EnrolUser from './panels/EnrolUser/EnrolUser';
import { featureIsEnabled, isLocal } from '../../../../globals/envSettings';
import { orgRoles } from '../../../../globals/orgRoles';
import getUrl from '../../../../globals/urls';
import { isHubMode } from '../../../../utils/platform';
import ProductFinderPanel from '../../../../components/ProductFinder/ProductFinderPanel';
import { resetForm } from '../../../../redux/reducers/enrolUser.reducer';

class StaffTab extends Component {
  componentDidMount() {
    const { clearSelectedUserAction } = this.props;
    clearSelectedUserAction();
  }

  _handleResendInvite = userId => {
    const { resendInvitationRequestAction, history, orgId } = this.props;
    history.push(safePanelLink('resendInvitation'));
    resendInvitationRequestAction({ userId, orgId });
  };

  _handleRemoveUser = userId => {
    const { history, setStaffToArchiveAction } = this.props;
    history.push(safePanelLink('archiveUsers'));
    setStaffToArchiveAction(userId);
  };

  _handleUnlockAccount = () => {
    const {
      setAccountToUnlockAction,
      people,
      selectedTeacherIds,
      orgId,
      setManagedUserAction,
      changeManagedUserPasswordRequestAction
    } = this.props;
    const userId = selectedTeacherIds[0];

    setManagedUserAction({
      userId,
      user: {
        firstName: people[userId].firstname,
        lastName: people[userId].lastname,
        username: people[userId].email
      },
      orgId
    });

    setAccountToUnlockAction(selectedTeacherIds);
    if (featureIsEnabled('lock-account')) {
      changeManagedUserPasswordRequestAction(orgId, selectedTeacherIds);
    }
  };

  _handleItemSelect = userId => {
    const { selectTeacher, selectedTeacherIds } = this.props;
    selectTeacher(userId, !selectedTeacherIds.includes(userId));
  };

  _handleItemLabelClick = userId => {
    const { orgId, history } = this.props;
    if (!isLocal()) {
      window.newrelic.interaction().actionText('Open staff profile');
    }
    history.push(getUrl({ type: 'PROFILE_USER', orgId, userId }));
  };

  _getSelectableUserIds = ids => {
    const { people } = this.props;
    const selectable = people
      ? Object.keys(
          pickBy(pick(people, ids), user => user.orgArchiveStatus !== USER_ARCHIVED_STATUS && !user.isDeleted)
        )
      : [];

    return selectable;
  };

  _getUserListingProps = () => {
    const { isOupUser, orgStaffDataRecency, selectedTeacherIds, orgPending, canArchive, authUserId } = this.props;

    return {
      className: 'gin-top2',
      authUserId,
      listingType: ListingTypes.JOIN,
      processingItems: orgStaffDataRecency.syncing ? orgStaffDataRecency.ids : [],
      selectedItems: selectedTeacherIds,
      onItemLabelClick: this._handleItemLabelClick,
      onItemSelect: this._handleItemSelect,
      onRemoveClick: canArchive ? this._handleRemoveUser : null,
      onResendInviteClick: orgPending && isOupUser ? this._handleResendInvite : undefined,
      orgPending
    };
  };

  _getProductFinderContextName = () => {
    const {
      people,
      selectedTeacherIds,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedTeacherIds.length === 1
      ? `${people[selectedTeacherIds[0]].firstname || ''} ${people[selectedTeacherIds[0]].lastname || ''}`
      : `${selectedTeacherIds.length} ${productFinderContent.staff_text}`;
  };

  render() {
    const {
      localizedContent: { mySchoolStaffTab: content, removeLearningMaterialModal: rlmModalContent },
      orgId,
      tabName,
      panelName,
      authUserRole,
      authUserId,
      people,
      selectedTeacherIds,
      searchTerm,
      sort,
      page,
      filters,
      orgAdminIds,
      teacherAdminIds,
      teacherIds,
      totalResults,
      showConfirmModal,
      history,
      triggerSearchAction,
      setSearchTerm,
      setSortAction,
      setPageAction,
      setFilterAction,
      setStaffToArchiveAction,
      closeModalAction,
      showModalAction,
      clearSelectedUserAction,
      clearStoreClassroomsAction,
      orgIsArchived,
      orgPending,
      resetJourneyAction,
      canArchive,
      canManageAssignments = true,
      resendInvitationResetAction,
      isOupUser,
      orgStaffDataRecency,
      loadingResults,
      reset
    } = this.props;

    const canUnlockAccount = selectedTeacherIds.length && selectedTeacherIds.every(id => people[id].isLocked);
    const restorableUserIds = selectedTeacherIds.filter(id => people[id].archiveStatus === USER_ARCHIVED_STATUS);
    const hasSelectedArchivableUsers =
      canArchive && !selectedTeacherIds.includes(authUserId) && !restorableUserIds.length;

    return (
      <div>
        <ListPageControls
          idPrefix="orgStaffSearch"
          searchInputLabel={content.search_staff_label}
          searchInputPlaceholder={`${content.search_text} ${content.staff_text}`}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={triggerSearchAction}
          disabled={orgIsArchived}
          newButtonText={orgPending ? content.button_add_org_admin : content.button_add_staff}
          newButtonTo={roleCanRegisterUser(authUserRole) ? safePanelLink('addStaff') : null}
          filterOptions={[
            {
              text: content.filter_options_active,
              id: 'orgStaffSearch-filterActive',
              name: 'orgStaffSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: content.filter_options_invited_staff,
              id: 'orgStaffSearch-filterInvited',
              name: 'orgStaffSearch-filterInvited',
              value: 'invited',
              checked: filters.invited,
              onChange: setFilterAction
            },
            {
              text: content.filter_options_removed_staff,
              id: 'orgStaffSearch-filterArchived',
              name: 'orgStaffSearch-filterArchived',
              value: 'archived',
              checked: filters.archived,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchUserSortOptions('orgStaffSearch', sort)}
          loading={orgStaffDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={orgStaffDataRecency.synced}
          loadedMessage={content.loaded}
          showSkeletonLoader={loadingResults}
        />
        <SearchStatus
          searchSource="orgStaff"
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="col">
                  <p className="gin-top1 gin-bot1">{content.no_search_results}</p>
                  {roleCanRegisterUser(authUserRole) && !orgIsArchived && (
                    <Link to={safePanelLink('addStaff')}>{content.add_staff_text}</Link>
                  )}
                </div>
              </div>
            </div>
          }
        />

        {loadingResults ? null : (
          <div className="grid horizantal-scroll-mobile">
            <div className="row">
              <div
                id="searchResults"
                className="col"
                role="region"
                aria-live="polite"
                aria-atomic="true"
                aria-label="School staff list"
              >
                {[
                  { ids: orgAdminIds, heading: content.table_school_admin_column },
                  { ids: teacherAdminIds, heading: content.table_teacher_admin_column },
                  { ids: teacherIds, heading: content.table_teachers_column }
                ].map(({ ids, heading }) =>
                  ids.length ? (
                    <UserListing
                      key={heading}
                      {...this._getUserListingProps()}
                      heading={heading}
                      items={pick(people, ids)}
                      selectable={this._getSelectableUserIds(ids)}
                      selectAllEnabled
                    />
                  ) : null
                )}
                {totalResults > 10 ? (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="orgStaffSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={setPageAction}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <SlideupPanel isOpen={!!selectedTeacherIds.length}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '2rem' }}>
              <Badge backgroundColor={colors.DASHBOARD} value={selectedTeacherIds.length} />
              <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{content.selected_text}</span>
            </div>
            {roleCanRegisterUser(authUserRole) && canUnlockAccount ? (
              <LinkWithIcon
                id="openUnlockAccounts"
                to={safePanelLink('unlockAccount')}
                action={this._handleUnlockAccount}
                text={content.button_unlock_account}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
            {canManageAssignments ? (
              <LinkWithIcon
                id="openAssignToStaff"
                to={safePanelLink('assignMaterial')}
                text={content.assign_learning_material_text}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
            {hasSelectedArchivableUsers ? (
              <LinkWithIcon
                id="archiveUsersLink"
                to={safePanelLink('archiveUsers')}
                action={() => setStaffToArchiveAction(selectedTeacherIds)}
                text={content.remove_staff_from_organisation}
                glyph={GLYPHS.ICON_RIGHT}
              />
            ) : null}
          </div>
        </SlideupPanel>
        {roleCanRegisterUser(authUserRole) && canUnlockAccount ? (
          <UnlockAccount
            orgId={orgId}
            people={people}
            userIds={selectedTeacherIds}
            panelId="unlockAccountsPopout"
            ariaLabel={content.aria_label_unlock_account}
            open={tabName === 'schoolStaff' && panelName === 'unlockAccount'}
            onClose={() => history.push(safePanelUnlink('unlockAccount'))}
          />
        ) : null}
        {roleCanRegisterUser(authUserRole) ? (
          <PopoutPanel id="enrolUserPopout" ariaLabel={content.aria_label_add_staff} isOpen={panelName === 'addStaff'}>
            <EnrolUser
              orgId={orgId}
              context={orgPending ? 'ORG_ADMIN' : 'ORG_STAFF'}
              closePanel={() => showModalAction(true)}
              onComplete={() => history.push(safePanelUnlink('addStaff'))}
            />
          </PopoutPanel>
        ) : null}
        {canManageAssignments ? (
          <PopoutPanel
            id="assignToStaffPopout"
            ariaLabel={content.aria_label_assign_material_to_staff}
            isOpen={tabName === 'schoolStaff' && panelName === 'assignMaterial'}
          >
            {featureIsEnabled('product-finder-refactor') && isHubMode() ? (
              <ProductFinderPanel
                orgId={orgId}
                selectedUsers={{ teacherIdList: [...selectedTeacherIds] }}
                contextName={this._getProductFinderContextName()}
                onClose={() => showModalAction(true)}
                onComplete={() => {
                  clearSelectedUserAction();
                  history.push(safePanelUnlink('assignMaterial'));
                }}
                // needed for polling. will be removed when polling is removed
                context="ORG_STAFF"
              />
            ) : (
              <AssignLearningMaterial
                context="ORG_STAFF"
                orgId={orgId}
                closePopoutAction={() => showModalAction(true)}
                onComplete={() => {
                  clearSelectedUserAction();
                  history.push(safePanelUnlink('assignMaterial'));
                }}
              />
            )}
          </PopoutPanel>
        ) : null}
        {canArchive ? (
          <UserArchivePanel
            id="archiveUsersPopout"
            ariaLabel={content.aria_label_archive_users}
            orgId={orgId}
            userType="staff"
            open={tabName === 'schoolStaff' && panelName === 'archiveUsers'}
            onClosePanel={() => showModalAction(true)}
            onComplete={() => {
              history.push(safePanelUnlink('archiveUsers'));
              clearSelectedUserAction();
            }}
          />
        ) : null}
        {orgPending && isOupUser ? (
          <ResendInvitationOutcomePanel
            id="resendInvitationPanel"
            ariaLabel={content.aria_label_resend_invitation}
            open={tabName === 'schoolStaff' && panelName === 'resendInvitation'}
            onComplete={() => {
              history.push(safePanelUnlink('resendInvitation'));
              resendInvitationResetAction();
            }}
          />
        ) : null}
        {showConfirmModal ? (
          <ConfirmationModal
            title={rlmModalContent.title}
            body={rlmModalContent.body}
            positiveClickText={rlmModalContent.positiveClickText}
            negativeClickText={rlmModalContent.negativeClickText}
            positiveClick={() => {
              reset();
              // Close the Popout panel
              showModalAction(false);
              clearSelectedUserAction();
              clearStoreClassroomsAction();
              // Once its closed then reset the archiveUsers form
              setTimeout(closeModalAction, 300);
              setTimeout(resetJourneyAction, 300);
              history.push(safePanelUnlink('archiveUsers'));
              history.push(safePanelUnlink('assignMaterial'));
              history.push(safePanelUnlink('addStaff'));
            }}
            negativeClick={() => showModalAction(false)}
          />
        ) : null}
      </div>
    );
  }
}

StaffTab.propTypes = {
  localizedContent: PropTypes.object.isRequired,

  orgId: PropTypes.any,
  tabName: PropTypes.string,
  panelName: PropTypes.string,

  people: PropTypes.object.isRequired,
  authUserRole: PropTypes.string.isRequired,
  authUserId: PropTypes.string.isRequired,
  selectedTeacherIds: PropTypes.array.isRequired,

  searchTerm: PropTypes.string.isRequired,
  sort: PropTypes.string,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  orgAdminIds: PropTypes.array.isRequired,
  teacherAdminIds: PropTypes.array.isRequired,
  teacherIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  orgStaffDataRecency: PropTypes.object.isRequired,

  history: PropTypes.object.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  selectTeacher: PropTypes.func.isRequired,
  setStaffToArchiveAction: PropTypes.func.isRequired,
  closeModalAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  orgIsArchived: PropTypes.bool.isRequired,
  orgPending: PropTypes.bool.isRequired,
  resetJourneyAction: PropTypes.func.isRequired,
  clearSelectedUserAction: PropTypes.func.isRequired,
  clearStoreClassroomsAction: PropTypes.func.isRequired,
  setManagedUserAction: PropTypes.func.isRequired,
  changeManagedUserPasswordRequestAction: PropTypes.func.isRequired,
  setAccountToUnlockAction: PropTypes.func.isRequired,
  canManageAssignments: PropTypes.bool,
  canArchive: PropTypes.bool.isRequired,
  isOupUser: PropTypes.bool.isRequired,
  resendInvitationRequestAction: PropTypes.func.isRequired,
  resendInvitationResetAction: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool,
  orgRole: PropTypes.oneOf(Object.keys(orgRoles)),
  reset: PropTypes.func.isRequired
};

export default compose(
  withSearchInitialiser({
    searchSource: 'orgStaff',
    initialFilters: {
      roles: [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER],
      active: true,
      invited: true,
      archived: false,
      isOrgStaffTab: true
    }
  }),
  withRouter,
  withLocalizedContent('mySchoolStaffTab', 'removeLearningMaterialModal', 'productFinder'),
  withDataRecency('orgStaff'),
  connect(
    (state, { orgId }) => {
      const staffids = featureIsEnabled('client-side-pagination')
        ? Object.keys(state.search.orgStaff.paginatedUserList)
        : state.search.orgStaff.ids;
      const staffrole = featureIsEnabled('client-side-pagination')
        ? id => state.search.orgStaff.paginatedUserList[id].roleName
        : id => state.people.data[id].roleName;
      const {
        [userRoles.ORG_ADMIN]: orgAdminIds = [],
        [userRoles.TEACHER_ADMIN]: teacherAdminIds = [],
        [userRoles.TEACHER]: teacherIds = []
      } = groupBy(staffids, staffrole);
      const orgPending = state.editOrg.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED;
      const hasSinglePendingOrgAdmin = orgPending && state.search.orgStaff?.initialHaveSingleOrg;

      return {
        ...pick(state.search.orgStaff, ['term', 'sort', 'page', 'filters', 'totalResults']),
        searchTerm: state.search.orgStaff.term,
        authUserId: state.identity.userId,
        authUserRole: state.identity.role,
        people: state.people.data,
        selectedTeacherIds: state.organisationPage.selectedTeacherIds,
        orgAdminIds,
        teacherAdminIds,
        teacherIds,
        showConfirmModal: state.archiveUsers.showModal,
        orgIsArchived: state.organisations.data[orgId] ? state.organisations.data[orgId].archived : false,
        orgPending,
        canArchive: roleCanArchiveOrgUser(state.identity.role) && !hasSinglePendingOrgAdmin,
        isOupUser: isAdminOrSupport(state),
        loadingResults: state.search.orgStaff ? state.search.orgStaff.loading : false,
        orgRole: state.organisations.data[state.identity.currentOrganisationId]?.role
      };
    },
    {
      setSearchTerm: term => setTerm('orgStaff', term),
      setSortAction: sort =>
        featureIsEnabled('client-side-pagination')
          ? setSortOrgStudent('orgStaff', sort[0])
          : setSort('orgStaff', sort[0]),
      setPageAction: page =>
        featureIsEnabled('client-side-pagination') ? setPageOrgStudent('orgStaff', page) : setPage('orgStaff', page),
      setFilterAction: (valueName, value) =>
        featureIsEnabled('client-side-pagination')
          ? setFilterOrgStudent('orgStaff', valueName, value)
          : setFilter('orgStaff', valueName, value),
      triggerSearchAction: () => triggerSearch('orgStaff'),
      selectTeacher: setTeacherSelected,
      setStaffToArchiveAction: setStaffToArchive,
      closeModalAction: closeForm,
      showModalAction: showClosePanelModal,
      resetJourneyAction: resetJourney,
      clearSelectedUserAction: clearSelectedUser,
      clearStoreClassroomsAction: clearStoreClassrooms,
      setManagedUserAction: payload => setManagedUser(payload),
      changeManagedUserPasswordRequestAction: (orgId, userId) => changeManagedUserPasswordRequest(orgId, userId),
      setAccountToUnlockAction: setAccountToUnlock,
      resendInvitationRequestAction: resendInvitationRequest,
      resendInvitationResetAction: resendInvitationReset,
      reset: resetForm
    }
  )
)(StaffTab);
