import React from 'react';
import PropTypes from 'prop-types';
import styles from './BackButton.scss';

function BackButton({ href }) {
  return (
    <button
      type="button"
      className={styles.backButton}
      onClick={() => {
        window.location.href = href;
      }}
    >
      back
    </button>
  );
}

BackButton.propTypes = {
  href: PropTypes.string.isRequired
};

export default BackButton;
