import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withLocalizedContent from '../../language/withLocalizedContent';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import styles from './LtiLicenceCreditsForm.scss';
import LtiLicenceProductItem from './LtiLicenceProductItem';

function LtiLicenceProductListItem({
  localizedContent: { ltiLicenceCreditsForm: localizedContent },
  onAddCredits,
  product,
  credits
}) {
  const [addedCredits, setAddedCredits] = useState(0);

  useEffect(() => {
    setAddedCredits(0);
  }, [credits, product]);

  const handleAddCredits = () => {
    onAddCredits(product.isbn, addedCredits);
  };

  return (
    <div className={styles.productListItem}>
      <LtiLicenceProductItem product={product} />
      <div className={styles.productAction}>
        <span className={styles.existingCredits}>{credits}</span>
        <strong>+</strong>
        <TextInputWithButton
          labelHidden
          className={styles.creditsInput}
          value={addedCredits}
          type="number"
          name="added-credits"
          disabled={false}
          onChange={setAddedCredits}
          onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          buttonTitle={localizedContent.apply_label}
          buttonAction={handleAddCredits}
        />
      </div>
    </div>
  );
}

LtiLicenceProductListItem.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  product: PropTypes.object,
  credits: PropTypes.number,
  onAddCredits: PropTypes.func
};

export default compose(withLocalizedContent('ltiLicenceCreditsForm'))(LtiLicenceProductListItem);
